<template>
	<w-layout column>
		<w-flex>
			<v-select
				v-model="model"
				:items="filteredCatalogTreeStructures"
				:label="label"
				:multiple="multiple"
				item-text="name"
				item-value="id"
				:return-object="returnObject"
			>
				<template v-slot:prepend-item>
					<w-search-input v-model="search" hide-details single-line :placeholder="label" />
				</template>
				<template v-slot:selection="{ item, index }">
					<span v-if="index > 0" class="mr-1">,</span>
					<span v-text="item.name"></span>
				</template>
			</v-select>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'AccountingFirmCatalogTreeStructures',
	props: {
		value: {
			required: false,
			type: [Array, Number],
			default: null
		},
		label: {
			required: true,
			type: String
		},
		multiple: {
			required: false,
			type: Boolean,
			default: false
		},
		returnObject: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			search: '',
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		filteredCatalogTreeStructures: function () {
			const search = this.search ? this.search.toLowerCase() : ''
			return this.items
				.filter(cts => cts.name.toLowerCase().includes(search))
		},
	}
}
</script>
